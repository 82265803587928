import { useMessageContext } from "../../context";

function useMessage() {
  const [{ message: messageState }, dispatch] = useMessageContext();

  function setSuccessMessage(message) {
    dispatch({
      type: "SUCCESS_MESSAGE",
      payload: message,
    });
  }

  function setErrorMessage(message) {
    dispatch({
      type: "ERROR_MESSAGE",
      payload: message,
    });
  }

  function removeMessage() {
    dispatch({
      type: "REMOVE_MESSAGE",
    });
  }

  return [messageState, setSuccessMessage, setErrorMessage, removeMessage];
}

export default useMessage;
